import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import HeartIcon from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {AlertTitle, CardActionArea, CardMedia, Collapse, TextField} from "@mui/material";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import FibonacciDefinition from './images/fibonacci-folge-definition.jpg'

import Quiz2Image from './images/quiz2.jpg'
import Quiz2ExampleCoordinates from './images/example-google-maps.jpg'
import FabienneInParis from './images/fabienne-in-paris.jpg'


const theme = createTheme();

export default function Quiz() {
    const [quiz1Answer, setQuiz1Answer] = useState(0);
    const [quiz1AnswerCorrect, setQuiz1AnswerCorrect] = useState(false);
    const [showQuiz1WrongAnswerAlert, setShowQuiz1WrongAnswerAlert] = React.useState(false);

    const [quiz2LatitudeAnswer, setQuiz2LatitudeAnswer] = useState(0);
    const [quiz2LongitudeAnswer, setQuiz2LongitudeAnswer] = useState(0);
    const [quiz2AnswerCorrect, setQuiz2AnswerCorrect] = useState(false);
    const [showQuiz2WrongAnswerAlert, setShowQuiz2WrongAnswerAlert] = useState(false);

    const [quiz3Answer, setQuiz3Answer] = useState("");
    const [quiz3AnswerCorrect, setQuiz3AnswerCorrect] = useState(false);
    const [showQuiz3WrongAnswerAlert, setShowQuiz3WrongAnswerAlert] = useState(false);

    const [challenges, setChallenges] = useState({});

    const fetchChallenges = () => {
        return fetch("https://fabiennes-christmas-present-2022-backend-em6322zopa-ew.a.run.app/challenges")
            .then((response) => response.json())
            .then((data) => {
                setChallenges(data)
            });
    }


    useEffect(() => {
        fetchChallenges();
    }, [quiz1AnswerCorrect, quiz2AnswerCorrect, quiz3AnswerCorrect])

    const onQuiz1AnswerChangeHandler = event => {
        setQuiz1Answer(event.target.value);
    };

    const onQuiz2LatitudeAnswerChangeHandler = event => {
        setQuiz2LatitudeAnswer(event.target.value);
    };

    const onQuiz2LongitudeAnswerChangeHandler = event => {
        setQuiz2LongitudeAnswer(event.target.value);
    };

    const onQuiz3AnswerChangeHandler = event => {
        setQuiz3Answer(event.target.value);
    };

    const onQuiz1SubmitAnswerButtonClickHandler = () => {
        putData('https://fabiennes-christmas-present-2022-backend-em6322zopa-ew.a.run.app/challenges/challenge-01', {answer: `${quiz1Answer}`})
            .then((data) => {
                setQuiz1AnswerCorrect(true);
                setShowQuiz1WrongAnswerAlert(false);
                console.log("Quiz 1 was correctly answered")
            })
            .catch((error) => {
                setShowQuiz1WrongAnswerAlert(true);
                console.error("Quiz 1 answer was wrong")
            });
    }

    const onQuiz2SubmitAnswerButtonClickHandler = () => {
        putData('https://fabiennes-christmas-present-2022-backend-em6322zopa-ew.a.run.app/challenges/challenge-02',
            {
                latitude: quiz2LatitudeAnswer,
                longitude: quiz2LongitudeAnswer
            })
            .then((response) => {
                setQuiz2AnswerCorrect(true);
                setShowQuiz2WrongAnswerAlert(false);
                console.log("Quiz 2 was correctly answered")
            })
            .catch((error) => {
                setShowQuiz2WrongAnswerAlert(true);
                console.error("Quiz 2 answer was wrong")
            });
    }

    const onQuiz3SubmitAnswerButtonClickHandler = () => {
        putData('https://fabiennes-christmas-present-2022-backend-em6322zopa-ew.a.run.app/challenges/challenge-03', {code: quiz3Answer})
            .then((data) => {
                setQuiz3AnswerCorrect(true);
                setShowQuiz3WrongAnswerAlert(false);
                console.log("Quiz 3 was correctly answered")
            })
            .catch((error) => {
                setShowQuiz3WrongAnswerAlert(true);
                console.error("Quiz 3 answer was wrong")
            });
    }

    async function putData(url = '', data = {}) {
        return await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        })
    }

    const shouldRenderGift = (challenges) => {

        if (!challenges) {
            return false;
        }

        if (challenges.length !== 3) {
            return false;
        }

        for (const element of challenges) {
            if (!element.solved) {
                return false;
            }
        }

        console.log(`gift should be rendered`)
        return true;
    }


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar position="relative">
                <Toolbar sx={{backgroundColor: '#346633'}}>
                    <HeartIcon sx={{color: 'red', mr: 2}}/>
                    <Typography variant="h6" color="red" noWrap>
                        Fabiennes Christmas Present 2022
                    </Typography>
                </Toolbar>
            </AppBar>


            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Frohe Weihnachten Fabienne
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" paragraph>
                            Liebe Fabienne, ich wünsche dir frohe Weihnachten und ich hoffe du hast Freude an deinem
                            Geschenk. Damit du allerdings überhaupt erst an dein Geschenk kommst, musst du dich noch ein
                            kleines bitzeli
                            anstrengen. Ich habe drei schwierige Aufgaben für dich vorbereitet. Die ersten beiden
                            Aufgaben kannst du selbst online lösen. Für die 3. Aufgabe bekommst du bei erfolgreicher
                            Beantwortung einen Code von mir, der dich dann zu deinem Geschenk führt, sofern du die
                            anderen
                            beiden Rätsel ebenso erfolgreich gelöst hast. Gutes Gelingen meine Liebe!<HeartIcon
                            sx={{color: 'red', mr: 2}}/>
                        </Typography>
                    </Container>
                </Box>

                {challenges.length > 0 &&
                    <Container sx={{py: 8}} maxWidth="md">

                        <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flexGrow: 1}}>
                                <Collapse in={showQuiz1WrongAnswerAlert}>
                                    <Alert
                                        severity={"error"}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setShowQuiz1WrongAnswerAlert(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit"/>
                                            </IconButton>
                                        }
                                        sx={{mb: 2}}
                                    >
                                        <AlertTitle>Falsche Antwort</AlertTitle>
                                        Die Antwort ist leider falsch!
                                    </Alert>
                                </Collapse>

                                <Typography gutterBottom variant="h4" component="h2">
                                    Aufgabe 1 - Wie lautet die Fibonacci Zahl #1050

                                    {(challenges[0].solved === true || quiz1AnswerCorrect) &&
                                        <CheckCircleIcon color={'success'}
                                                         fontSize={'large'}
                                                         sx={{marginLeft: '10px'}}
                                        />
                                    }

                                </Typography>
                                <Typography>
                                    Ich möchte von dir wissen wie die 1050 Fibonacci Zahl lautet. Falls dir eine
                                    Fibonacci Zahl kein Begriff ist findest du unten eine Definition (ansonsten einfach
                                    nach der Definition im Internet suchen). Ich bin schon äusserst gespannt wie du das
                                    Problem löst ohne nach der
                                    Lösung online zu googeln. Wenn du nicht weiterkommst kann dir sicherlich auch Stefan
                                    dabei
                                    helfen, für ihn sollte das kein Problem darstellen :)
                                </Typography>

                                <CardMedia
                                    component="img"
                                    image={FibonacciDefinition}
                                    sx={{marginTop: '30px', marginBottom: '15px'}}
                                />
                            </CardContent>
                            {challenges[0].solved !== true &&
                                <CardActions>
                                    <TextField
                                        id="outlined-number"
                                        label="Antwort"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onQuiz1AnswerChangeHandler}
                                        value={quiz1Answer}
                                    />
                                    <Button variant="outlined"
                                            sx={{
                                                marginLeft: '10px',
                                                height: '56px'
                                            }}
                                            onClick={onQuiz1SubmitAnswerButtonClickHandler}
                                    >Absenden</Button>
                                </CardActions>
                            }
                        </Card>

                        <Card sx={{marginTop: '25px', height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flexGrow: 1}}>
                                <Collapse in={showQuiz2WrongAnswerAlert}>
                                    <Alert
                                        severity={"error"}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setShowQuiz2WrongAnswerAlert(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit"/>
                                            </IconButton>
                                        }
                                        sx={{mb: 2}}
                                    >
                                        <AlertTitle>Falsche Antwort</AlertTitle>
                                        Die Antwort ist leider falsch!
                                    </Alert>
                                </Collapse>

                                <Typography gutterBottom variant="h4" component="h2">
                                    Aufgabe 2 - Wo ist das?

                                    {(challenges[1].solved === true || quiz2AnswerCorrect) &&
                                        <CheckCircleIcon color={'success'}
                                                         fontSize={'large'}
                                                         sx={{marginLeft: '10px'}}
                                        />
                                    }

                                </Typography>
                                <Typography>
                                    Das Bild zeigt einen bekannten Ort in den USA. Du sollst den Ort auf Google Maps
                                    ausfindig machen und die Koordinaten der im Bild markierten Stelle finden. Das
                                    machst du indem du auf Google Maps eine bestimmte Stelle anklickst. Danach sollte
                                    dir Google Maps die Koordinaten (Latitude und Longitude) der ausgewählten Stelle
                                    anzeigen. Die Position muss nicht exakt übereinstimmen sondern muss auf mindestens 3
                                    Nachkommstellen genau sein. Vergiss nicht, dass die Zahl auch negativ sein kann :)
                                </Typography>
                                <ul>
                                    <li>Der gesuchte Ort befindet sich geographisch etwa in der Mitte der USA
                                    </li>
                                    <li>Es hat mit 4 Personen zu tun:
                                        <ul>
                                            <li>
                                                Person 1 (22.02.1732)
                                            </li>
                                            <li>
                                                Person 2 (13.04.1743)
                                            </li>
                                            <li>
                                                Person 3 (27.10.1858)
                                            </li>
                                            <li>
                                                Person 4 (12.02.1809)
                                            </li>
                                        </ul></li>
                                </ul>
                                <CardMedia
                                    component="img"
                                    height="auto"
                                    image={Quiz2Image}
                                />

                                <Typography variant="h6" component="h6" sx={{marginTop: '10px'}}>
                                    Beispiel Koordinaten auf Google Maps
                                </Typography>
                                <CardMedia
                                    component="img"
                                    image={Quiz2ExampleCoordinates}
                                    sx={{marginTop: '10px', marginBottom: '25px'}}
                                />

                            </CardContent>
                            {challenges[1].solved !== true &&
                                <CardActions>
                                    <TextField
                                        id="outlined-number"
                                        label="Latitude"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onQuiz2LatitudeAnswerChangeHandler}
                                        value={quiz2LatitudeAnswer}
                                    />
                                    <TextField
                                        id="outlined-number"
                                        label="Longitude"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onQuiz2LongitudeAnswerChangeHandler}
                                        value={quiz2LongitudeAnswer}
                                    />
                                    <Button variant="outlined"
                                            sx={{
                                                marginLeft: '10px',
                                                height: '56px'
                                            }}
                                            onClick={onQuiz2SubmitAnswerButtonClickHandler}
                                    >Absenden</Button>
                                </CardActions>
                            }
                        </Card>

                        <Card sx={{marginTop: '25px', height: '100%', display: 'flex', flexDirection: 'column'}}>
                            <CardContent sx={{flexGrow: 1}}>
                                <Collapse in={showQuiz3WrongAnswerAlert}>
                                    <Alert
                                        severity={"error"}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="error"
                                                size="small"
                                                onClick={() => {
                                                    setShowQuiz3WrongAnswerAlert(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit"/>
                                            </IconButton>
                                        }
                                        sx={{mb: 2}}
                                    >
                                        <AlertTitle>Falsche Antwort</AlertTitle>
                                        Die Antwort ist leider falsch!
                                    </Alert>
                                </Collapse>

                                <Typography gutterBottom variant="h4" component="h2">
                                    Aufgabe 3 - 10 Dinge warum du mich gern hast

                                    {(challenges[2].solved === true || quiz3AnswerCorrect) &&
                                        <CheckCircleIcon color={'success'}
                                                         fontSize={'large'}
                                                         sx={{marginLeft: '10px'}}
                                        />
                                    }

                                </Typography>
                                <Typography>
                                    Du bekommst das ganze Jahr über viele Komplimente von mir. Nutze die Gelegenheit um
                                    selbst ein paar Komplimente zu verteilen und schreib mir auf Whatsapp ein paar
                                    schöne Dinge und lass mich wissen, warum du mich gärn hast. <HeartIcon
                                    sx={{color: 'red'}}/>

                                    <br></br>
                                    <br></br>
                                    Hier ein paar Inspirationen:

                                    <ul>
                                        <li>Was schätzt du an mir?</li>
                                        <li>Wieso liebst du mich?</li>
                                        <li>Was war unser schönster gemeinsamer Moment letztes Jahr?</li>
                                    </ul>

                                    <br></br>
                                    Bei erfolgreicher Beantwortung verrate ich dir das Codewort!
                                </Typography>
                            </CardContent>
                            {challenges[2].solved !== true &&
                                <CardActions>
                                    <TextField
                                        id="outlined-number"
                                        label="Code"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={onQuiz3AnswerChangeHandler}
                                        value={quiz3Answer}
                                    />
                                    <Button variant="outlined"
                                            sx={{
                                                marginLeft: '10px',
                                                height: '56px'
                                            }}
                                            onClick={onQuiz3SubmitAnswerButtonClickHandler}
                                    >Absenden</Button>
                                </CardActions>
                            }
                        </Card>

                    </Container>
                }

                {shouldRenderGift(challenges) &&

                    <Container sx={{py: 8}} maxWidth="md">
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="650"
                                    image={FabienneInParis}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        Dein Geschenk
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Wuhuuu Geschafft! Ich schenke dir eine gemeinsame Reise in die Stadt der Liebe.
                                        Dabei komme ich für die Anreise per Zug oder Flugzeug auf und ebenso für 1
                                        Übernachtung. In Paris gibt's für dich sicherlich so einige spannende und
                                        sehenswürdige Dinge zu erleben. Ich hoffe du hast Freude daran. Die Details
                                        können wir ja gemeinsam besprechen. <HeartIcon sx={{color: 'red'}}/>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Container>
                }
            </main>
        </ThemeProvider>
    );
}